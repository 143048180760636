"use client";
import { useState, useEffect } from "react";
import Script from "next/script";

const BitrixScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    // Delay the script load by 5 seconds
    const timeout = setTimeout(() => {
      setIsScriptLoaded(true);
    }, 5000);

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, []);

  return (
    <>
      {isScriptLoaded && (
        <Script
          id="bitrix24-script"
          src="https://cdn.bitrix24.com/b12529265/crm/site_button/loader_3_zyjjkf.js"
          strategy="lazyOnload" // Loads script lazily after the page is interactive
          onLoad={console.log("LOADED SCRIPT")}
        />
      )}
    </>
  );
};

export default BitrixScript;
